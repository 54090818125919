import { InfiniteData } from '@tanstack/react-query';
import React from 'react';
import { Link } from 'react-router-dom';

import { ShiftResponse } from '../../services/models/ShiftResponse';
import { EntityType } from '../EntityType';
import TestId from '../TestId';
import { Shift } from '../models';
import { getEntityName } from '../util/getEntityName';
import LoadingSpinner from './LoadingSpinner';
import ShiftSummary from './ShiftSummary';

type Props = {
  entityType: EntityType;
  error: unknown;
  status: 'error' | 'loading' | 'success';
  hasNextPage: boolean | undefined;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  data: InfiniteData<ShiftResponse> | undefined;
};

export default function ShiftList({
  data,
  status,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
  error,
  entityType,
}: Props) {
  const entityName = getEntityName(entityType) + 's';

  return (
    <>
      {status === 'loading' || (isFetching && !isFetchingNextPage) ? (
        <LoadingSpinner atCenter={true} />
      ) : status === 'error' ? (
        <div className="messagebox text-red">{(error as Error).message}</div>
      ) : (
        <div
          className="flex flex-wrap items-start gap-x-4 lg:gap-x-5 gap-y-4"
          data-testid={
            entityType === EntityType.ShiftOffer
              ? TestId.ShiftOffers
              : TestId.MyShifts
          }
        >
          {data?.pages.map((page, i) => (
            <React.Fragment key={i}>
              {i === 0 && page.data.length === 0 ? (
                <div className="messagebox w-full">
                  {entityType === EntityType.Timesheet
                    ? `You don’t currently have any ${entityName}.`
                    : `Sorry, you don’t currently have any ${entityName}.`}
                </div>
              ) : (
                page.data.map((shift: Shift) => (
                  <div
                    className="w-full md:w-half inline-block text-left text-secondaryBlack p-0"
                    key={shift.shiftID}
                    data-testid={TestId.Shift}
                  >
                    <Link
                      to={`${
                        entityType === EntityType.ShiftOffer
                          ? shift.shiftOfferID
                          : shift.shiftID
                      }`}
                    >
                      <ShiftSummary
                        shiftData={shift}
                        entityType={entityType}
                        isOffer={entityType === EntityType.ShiftOffer}
                      />
                    </Link>
                  </div>
                ))
              )}
            </React.Fragment>
          ))}
        </div>
      )}

      <div>
        {isFetching && isFetchingNextPage ? (
          <LoadingSpinner />
        ) : hasNextPage ? (
          <div className="h-19" />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
