import { CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import OneSignal from 'react-onesignal';
import { Link } from 'react-router-dom';
import { getMyShifts, getShiftOffers } from '../services/ShiftApi';
import { getTimesheets } from '../services/TimesheetApi';
import { EntityType } from '../shared/EntityType';
import NavLinks from '../shared/NavLinks';
import { TimesheetTab } from '../shared/TimesheetTab';
import CountBadge from '../shared/components/CountBadge';
import ShiftSummary from '../shared/components/ShiftSummary';
import { usePushNotificationsSupported } from '../shared/hooks/usePushNotificationsSupported';

export default function Home() {
  const { pushNotificationsSupported } = usePushNotificationsSupported();

  const {
    isLoading: isOffersLoading,
    isError: isOffersError,
    data: offers,
    error: offersError,
  } = useQuery({
    queryKey: ['homeOffers'],
    queryFn: getShiftOffers,
    retry: (_failureCount, error) =>
      (error as HTTPError).response.status !== 423 && _failureCount <= 3,
  });

  const {
    isLoading: isTimesheetsLoading,
    isError: isTimesheetsError,
    data: timesheets,
  } = useQuery({
    queryKey: [`homeTimesheets`],
    queryFn: () => getTimesheets(TimesheetTab.Outstanding),
  });

  const {
    isLoading: isShiftsLoading,
    isError: isShiftsError,
    data: shifts,
  } = useQuery({
    queryKey: ['homeShifts'],
    queryFn: getMyShifts,
  });

  const shift = shifts?.data[0];
  const shiftCount = shifts?.data.length ?? 0;
  const offer = offers?.data[0];
  const offerCount = offers?.data.length ?? 0;
  const timesheet = timesheets?.data[0];
  const timesheetCount = timesheets?.data.length ?? 0;

  if (pushNotificationsSupported) {
    OneSignal.showSlidedownPrompt();
  }

  return (
    <div className="flex flex-col items-center pt-6">
      <div id="offers" className="w-full md:w-half pb-7">
        <div className="flex items-center pb-3">
          <h2 className="text-xl font-bold mr-2">
            <Link to={`${NavLinks.ShiftOffers}`}>Shift Offers</Link>
          </h2>
          <span className="grow">
            <CountBadge count={offerCount} />
          </span>
          {offerCount > 0 && <ViewAllLink to={NavLinks.ShiftOffers} />}
        </div>
        {isOffersLoading ? (
          <Spinner label="Loading shift offers" />
        ) : isOffersError ? (
          (offersError as HTTPError).response.status === 423 ? (
            <div className="messagebox text-red">
              <Link to={`${NavLinks.ShiftOffers}`}>
                You are currently unable to receive shift offers. Contact your
                ONCALL service representative for more information.
              </Link>
            </div>
          ) : (
            <div className="messagebox text-red">
              Could not load shift offers
            </div>
          )
        ) : offer ? (
          <div className="border-t-primaryOrange border-t-4 rounded">
            <Link to={`${NavLinks.ShiftOffers}/${offer.shiftOfferID}`}>
              <ShiftSummary
                shiftData={offer}
                entityType={EntityType.ShiftOffer}
                isOffer={true}
              />
            </Link>
          </div>
        ) : (
          <>You don't have any shift offers currently.</>
        )}
      </div>
      <div id="timesheets" className="w-full md:w-half pb-7">
        <div className="flex items-center pb-3">
          <h2 className="text-xl font-bold mr-2">
            <Link to={`${NavLinks.Timesheets}`}>Due Timesheets</Link>
          </h2>
          <span className="grow">
            <CountBadge count={timesheetCount} />
          </span>
          {timesheetCount > 0 && <ViewAllLink to={NavLinks.Timesheets} />}
        </div>
        {isTimesheetsLoading ? (
          <Spinner label="Loading due timesheets" />
        ) : isTimesheetsError ? (
          <div className="messagebox text-red">
            Could not load due timesheets
          </div>
        ) : timesheet ? (
          <div className="border-t-primaryGreen border-t-4 rounded">
            <Link to={`${NavLinks.Timesheets}/${timesheet?.shiftID}`}>
              <ShiftSummary
                shiftData={timesheet}
                entityType={EntityType.Timesheet}
              />
            </Link>
          </div>
        ) : (
          <>You don't have any due timesheets currently.</>
        )}
      </div>
      <div id="shifts" className="w-full md:w-half pb-7">
        <div className="flex items-center pb-3">
          <h2 className="text-xl font-bold mr-2">
            <Link to={`${NavLinks.MyShifts}`}>My Shifts</Link>
          </h2>
          <span className="grow">
            <CountBadge count={shiftCount} />
          </span>
          {shiftCount > 0 && <ViewAllLink to={NavLinks.MyShifts} />}
        </div>
        {isShiftsLoading ? (
          <Spinner label="Loading shifts" />
        ) : isShiftsError ? (
          <div className="messagebox text-red">Could not load shifts</div>
        ) : shift ? (
          <div className="border-t-secondaryGrey border-t-4 rounded">
            <Link to={`${NavLinks.MyShifts}/${shift.shiftID}`}>
              <ShiftSummary shiftData={shift} entityType={EntityType.MyShift} />
            </Link>
          </div>
        ) : (
          <>You don't have any shifts currently.</>
        )}
      </div>
    </div>
  );
}

function Spinner({ label }: { label: string }) {
  return (
    <div className="flex justify-center">
      <CircularProgress
        className="h-8 w-8 text-secondaryBlack"
        aria-label={label}
      />
    </div>
  );
}

function ViewAllLink({ to }: { to: string }) {
  return (
    <Link className="text-sm text-hyperlink uppercase" to={to}>
      View All &rsaquo;
    </Link>
  );
}
