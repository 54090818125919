import moment from 'moment';

export const getMomentFromDateAndTime = (
  date: string,
  time: string,
  startTime?: string
) => {
  const tm = time.split(':');

  const datetime = moment(date).set({
    hour: parseInt(tm[0]),
    minute: parseInt(tm[1]),
  });

  if (startTime) {
    const tm2 = startTime.split(':');

    const startDateTime = moment(date).set({
      hour: parseInt(tm2[0]),
      minute: parseInt(tm2[1]),
    });

    if (time < '12:00' && datetime.isBefore(startDateTime)) {
      datetime.add(1, 'days');
    }
  }

  return datetime;
};
