import { CircularProgress } from '@mui/material';

type Props = {
  atCenter?: boolean;
};

export default function LoadingSpinner({ atCenter = true }: Props) {
  const elementId = 'spinner';

  const el = document.getElementById(elementId);
  const offsetTop = el?.offsetTop || 0;
  const top = offsetTop + 40; // 40px in accordance with the tailwind class pb-10 used in App.tsx

  return (
    <div
      id={elementId}
      style={atCenter ? { minHeight: 'calc(100vh - ' + top + 'px)' } : {}}
      className={
        'flex flex-col justify-center items-center pt-9 ' +
        (atCenter ? 'pb-9' : '')
      }
    >
      <CircularProgress
        sx={{ color: 'black' }}
        size={40}
        thickness={4}
        aria-label="Loading"
      />
    </div>
  );
}
