import CheckIcon from '@mui/icons-material/Check';
import { Alert, AlertColor } from '@mui/material';
import { useState } from 'react';

export default function AlertMessage({
  severity = 'success',
  children,
  className,
}: Props) {
  const [visible, setVisible] = useState<boolean>(true);

  return !visible ? (
    <></>
  ) : (
    <Alert
      className={
        'border mb-5 px-3 py-0 font-sans text-secondaryBlack' +
        (severity === 'success' ? ' border border-primaryGreen' : '') +
        ` ${className}`
      }
      severity={severity}
      onClose={() => setVisible(false)}
      iconMapping={{
        success: (
          <CheckIcon fontSize="inherit" className="text-secondaryBlack" />
        ),
      }}
    >
      {children}
    </Alert>
  );
}

type Props = {
  severity?: AlertColor;
  children: JSX.Element | string;
  className?: string;
};
