import { useSearchParams } from 'react-router-dom';

import Title from '../shared/components/Title';

import { useInfiniteQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import { getShiftOffers } from '../services/ShiftApi';
import { EntityType } from '../shared/EntityType';
import AlertMessage from '../shared/components/AlertMessage';
import ShiftList from '../shared/components/ShiftList';
import { useHandleScroll } from '../shared/hooks/useHandleScroll';

export default function ShiftOffersList() {
  const [searchParams] = useSearchParams();

  const isDeclined = !!searchParams.get('declined');

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['shiftOffers'],
    queryFn: getShiftOffers,
    retry: (_failureCount, error) =>
      (error as HTTPError).response.status !== 423 && _failureCount <= 3,
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  useHandleScroll(hasNextPage, fetchNextPage, isFetching);

  return (
    <>
      <Title title="Shift offers" />

      {isDeclined && (
        <AlertMessage severity="info" className="font-bold">
          The shift offer has been declined.
        </AlertMessage>
      )}

      {status === 'error' && (error as HTTPError).response.status === 423 ? (
        <AlertMessage severity="warning" className="font-bold">
          Your account status is Inactive, which means you are temporarily
          unable to receive shift offers. To resolve this, please contact your
          ONCALL service representative. We appreciate your prompt attention to
          this matter, and are here to assist you in resolving it swiftly.
        </AlertMessage>
      ) : (
        <ShiftList
          entityType={EntityType.ShiftOffer}
          data={data}
          error={error}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          status={status}
        />
      )}
    </>
  );
}
