import { useParams } from 'react-router';

import { useQuery } from '@tanstack/react-query';
import { redirect, useSearchParams } from 'react-router-dom';
import { getShift } from '../services/ShiftApi';
import { EntityType } from '../shared/EntityType';
import NavLinks from '../shared/NavLinks';
import AlertMessage from '../shared/components/AlertMessage';
import LoadingSpinner from '../shared/components/LoadingSpinner';
import ShiftDetail from '../shared/components/ShiftDetail';
import Title from '../shared/components/Title';

const shiftDetailsQuery = (id: number) => ({
  queryKey: ['myshift', id],
  queryFn: async () => getShift(id),
});

export default function MyShiftDetails() {
  const { id } = useParams();
  if (!id) {
    throw redirect(NavLinks.MyShifts);
  }

  const shiftId = parseInt(id);

  const [searchParams] = useSearchParams();

  const { isLoading, isError, data } = useQuery(shiftDetailsQuery(shiftId));

  const isAccepted = !!searchParams.get('accepted');

  const message: JSX.Element = isAccepted ? (
    <AlertMessage severity="warning">
      <span>
        <strong>Shift has been reserved!</strong>
        <br></br>
        <br></br>Please wait for a shift confirmation from your ONCALL service
        representative. You may receive a phone call or a confirmation via SMS.
        Also check back here to the My Shifts section for when the shift updates
        from ‘PENDING’ to CONFIRMED’
        <br></br>
        <br></br>
        <strong>Note:</strong> Please do <strong>NOT</strong> attend the shift
        until you have received this confirmation. Contact your ONCALL service
        representative for all queries.
      </span>
    </AlertMessage>
  ) : (
    <></>
  );

  return (
    <>
      <Title title="Shift details" />

      {isLoading ? (
        <LoadingSpinner />
      ) : isError ? (
        <div className="messagebox w-full">
          Details for this shift are not available at this time.
        </div>
      ) : (
        <ShiftDetail
          entityType={EntityType.MyShift}
          shift={data}
          message={message}
        />
      )}
    </>
  );
}
