import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Title from '../shared/components/Title';

export default function Error() {
  const error = useRouteError();

  return (
    <div>
      <Title title="My Availability" />
      <div className="messagebox w-full">
        {isRouteErrorResponse(error) ? (
          <p>
            {error.status} {error.statusText}
          </p>
        ) : (
          <>Availability cannot be viewed at this time.</>
        )}
      </div>
    </div>
  );
}
