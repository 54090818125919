import { postApi, queryApi } from './api';
import { ProfileResponse } from './models/ProfileResponse';

export async function getProfile() {
  const apiResponse = await queryApi(`/profiles/my`);

  return (await apiResponse.json()) as ProfileResponse;
}

export async function registerDevice(deviceId: string) {
  const apiResponse = await postApi(
    `/profiles/my/registerdevice`,
    JSON.stringify({
      PlayerId: deviceId,
    })
  );

  return await apiResponse.json();
}

type isSubscribedResponse = {
  Subscribed: boolean;
};

export async function isSubscribed(deviceId: string) {
  const apiResponse = await queryApi(`/profiles/my/issubscribed/${deviceId}`);

  return (await apiResponse.json<isSubscribedResponse>()).Subscribed === true;
}

type isRegisteredResponse = {
  Registered: boolean;
};

export async function isRegistered(deviceId: string) {
  const apiResponse = await queryApi(`/profiles/my/isregistered/${deviceId}`);

  return (await apiResponse.json<isRegisteredResponse>()).Registered === true;
}
