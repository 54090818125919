import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Outlet, useNavigation } from 'react-router-dom';

import { loginRequest } from './authConfig';
import Header from './shared/components/Header';

import './App.css';
import LoadingSpinner from './shared/components/LoadingSpinner';
import useOneSignal from './shared/hooks/useOneSignal';

const GoogleAnalyticsMeasurementId =
  process.env.REACT_APP_GA_MEASUREMENT_ID ?? '';

if (GoogleAnalyticsMeasurementId) {
  ReactGA.initialize(GoogleAnalyticsMeasurementId);
}

export default function App() {
  const { oneSignalInitialized } = useOneSignal();

  const navigation = useNavigation();
  const busy =
    navigation.state === 'loading' || navigation.state === 'submitting';

  library.add(far, fas, fab);

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      setTimer(
        setTimeout(() => {
          instance.loginRedirect(loginRequest);
        }, 1000)
      );
    } else {
      clearTimeout(timer);
    }
  }, [inProgress, isAuthenticated, instance]);

  return (
    <>
      {isAuthenticated ? (
        <div className="pt-20 pb-10">
          <Header />

          <div className={`mx-2 md:mx-8${busy ? ' animate-pulse' : ''}`}>
            <Outlet context={oneSignalInitialized} />
          </div>
        </div>
      ) : inProgress === InteractionStatus.HandleRedirect ||
        inProgress === InteractionStatus.Startup ? (
        <></>
      ) : (
        <LoadingSpinner atCenter={true} />
      )}
    </>
  );
}
