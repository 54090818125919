import { TimesheetTab } from '../shared/TimesheetTab';
import { Shift } from '../shared/models';
import { SubmittedTimesheet, Timesheet } from '../shared/models/Timesheet';
import { getShift } from './ShiftApi';
import { postApi, queryApi } from './api';
import { ShiftResponse } from './models/ShiftResponse';
import { TimesheetSubmitRequest } from './models/TImesheetSubmitRequest';
import { TimesheetSubmitResponse } from './models/TimesheetSubmitResponse';

const TIMESHEETS_PAGE_SIZE = 10;

export const getTimesheets = async (
  timesheetType: TimesheetTab,
  pageParam = 0
): Promise<ShiftResponse> => {
  const resp = await queryApi(
    `/timesheets/my/${timesheetType}?StartPage=${
      pageParam + 1
    }&PageSize=${TIMESHEETS_PAGE_SIZE}`
  );

  const shifts = (await resp.json()) as Shift[];

  return Promise.resolve(
    shifts.length === TIMESHEETS_PAGE_SIZE
      ? {
          data: shifts,
          nextPage: pageParam + 1,
        }
      : {
          data: shifts,
          // Only include nextPage if there are more results
        }
  );
};

export const getTimesheet = async (id: number) => {
  const timesheet = (await getShift(id)) as Timesheet;

  if (timesheet.timeSheetStatus !== 'Submitted') {
    return timesheet;
  }

  const response = await queryApi(`/timesheets/my/submitted/${id}`);

  const submittedTimesheet = (await response.json()) as SubmittedTimesheet;

  timesheet.toFromHoursAllowance = submittedTimesheet.toFromHoursAllowance;
  timesheet.toFromDistanceAllowanceInKm =
    submittedTimesheet.toFromDistanceAllowanceInKm;
  timesheet.startDate = submittedTimesheet.actualStartTime;
  timesheet.endDate = submittedTimesheet.actualEndTime;
  timesheet.breakTimeInMinutes = submittedTimesheet.mealBreakInMins;
  timesheet.participantTransportDistanceInKm =
    submittedTimesheet.participantTransportDistanceInKm;
  timesheet.sleepoverActiveHours = submittedTimesheet.sleepoverActiveHours;
  timesheet.reason = submittedTimesheet.reasonsInB64
    ? atob(submittedTimesheet.reasonsInB64)
    : '';

  return timesheet;
};

export const submitTimesheet = async (
  id: number,
  params: TimesheetSubmitRequest
): Promise<TimesheetSubmitResponse> => {
  const apiResponse = await postApi(
    `/timesheets/my/${id}`,
    JSON.stringify(params)
  );

  return await apiResponse.json();
};
