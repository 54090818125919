import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/base';
import { useNavigate } from 'react-router-dom';

export default function BackNavButton() {
  const navigate = useNavigate();

  return (
    <Button
      className="w-20 h-10 bg-grey1 text-xs shadow-button"
      onClick={() => navigate(-1)}
    >
      <div className="flex align-middle justify-center mx-2.5">
        <FontAwesomeIcon
          className="text-sm pt-0.25"
          icon={['fas', 'chevron-left']}
        />
        <span className="ml-2">Back</span>
      </div>
    </Button>
  );
}
