import { ButtonBase } from '@mui/material';
import ModalDialog from './ModalDialog';
import { SpinnerIcon } from './SpinnerIcon';

export default function ConfirmDialog(props: Props) {
  const {
    title,
    body,
    open,
    type = DialogType.Notify,
    errorMessage,
    isProcessing: isLoading,
    onClose,
    onConfirm,
    onCancel,
  } = props;

  return (
    <ModalDialog onClose={onClose} title={title} open={open} className="w-84">
      <div className="px-3 py-3">
        <div className="h-15 flex flex-col justify-center">
          <div>{body}</div>
        </div>

        {type === DialogType.Notify && (
          <div className="text-right mt-2">
            <ButtonBase
              className="w-28 h-11 rounded text-secondaryWhite bg-secondaryBlack font-normal"
              onClick={onClose}
            >
              <span>OK</span>
            </ButtonBase>
          </div>
        )}

        {type === DialogType.ConfirmOrCancel && (
          <div
            className={
              'flex justify-between' + (isLoading ? ' opacity-60' : '')
            }
          >
            <ButtonBase
              className="w-28 h-11 text-secondaryWhite bg-secondaryBlack font-normal shadow-button"
              disabled={isLoading}
              onClick={onCancel}
            >
              <span>Cancel</span>
            </ButtonBase>

            <ButtonBase
              className="w-28 h-11 text-secondaryBlack bg-secondaryWhite font-normal shadow-button border border-solid border-primaryGreen"
              disabled={isLoading}
              onClick={onConfirm}
            >
              {isLoading && (
                <SpinnerIcon isLoading={isLoading} className="mr-4" />
              )}
              <span>Confirm</span>
            </ButtonBase>
          </div>
        )}

        {errorMessage && (
          <div className="mt-5 text-sm text-red">{errorMessage}</div>
        )}
      </div>
    </ModalDialog>
  );
}

interface Props {
  title: JSX.Element;
  body: string;
  open: boolean;
  type?: DialogType;
  errorMessage?: string;
  isProcessing?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export enum DialogType {
  Notify = 'OK',
  ConfirmOrCancel = 'YesNo',
}
