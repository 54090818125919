import NavLinks from '../NavLinks';
import { MenuItem } from '../models';

export const getMenuItems = (): MenuItem[] => [
  { label: 'Home', url: NavLinks.Home },
  { label: 'Shift Offers', url: NavLinks.ShiftOffers },
  { label: 'Timesheets', url: NavLinks.Timesheets },
  { label: 'My Shifts', url: NavLinks.MyShifts },
  { label: 'My Availability', url: NavLinks.Availability },
  { label: 'Help', url: NavLinks.Help },
];
