import { Tab, Tabs } from '@mui/material';
import { TimesheetTab } from '../shared/TimesheetTab';
import Title from '../shared/components/Title';
import TimesheetListTab from './TimesheetListTab';

import { useNavigate, useSearchParams } from 'react-router-dom';
import './TimesheetList.css';

export default function TimesheetList() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const activeTab =
    searchParams.get('tab') === TimesheetTab.Submitted
      ? TimesheetTab.Submitted
      : TimesheetTab.Outstanding;

  return (
    <>
      <Title title="Timesheets" />

      <Tabs
        value={activeTab}
        onChange={(_e, value) => navigate(`?tab=${value}`)}
        aria-label="Timesheet tabs"
        className="mb-4"
      >
        <Tab label="DUE" value={TimesheetTab.Outstanding} />
        <Tab label="SUBMITTED" value={TimesheetTab.Submitted} />
      </Tabs>

      <TimesheetListTab
        key="outstanding"
        type={TimesheetTab.Outstanding}
        isVisible={activeTab === TimesheetTab.Outstanding}
      />

      <TimesheetListTab
        key="submitted"
        type={TimesheetTab.Submitted}
        isVisible={activeTab === TimesheetTab.Submitted}
      />
    </>
  );
}
