import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Drawer } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';

import Toolbar from '@mui/material/Toolbar';
import Navigation from './Navigation';
import SignInSignOutButton from './SignInSignOutButton';

import { Box } from '@mui/system';
import { getMenuItems } from '../util/getMenuItems';

import { Link, useNavigate } from 'react-router-dom';
import img from '../../assets/oncall1.png';
import NavLinks from '../NavLinks';
import { MenuItem } from '../models';
import './Header.css';

export const menuItems = getMenuItems();

export default function Header() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const container = window.document.body;

  const toggleMenu = () => {
    setOpen((prevState) => !prevState);
    return false;
  };

  const drawer = (
    <Box>
      <IconButton
        aria-label="Close menu"
        className="absolute right-1 block py-1 pointer-events-auto"
        onClick={toggleMenu}
        aria-labelledby="Close drawer"
      >
        <CloseIcon className="text-grey6 text-lg" />
      </IconButton>

      <ul className="w-84 p-4">
        {menuItems.map((item: MenuItem) => (
          <li key={item.label} className="pb-0 py-2.5">
            <div className="border-b-grey6 border-b pb-2.5">
              <Link to={item.url} onClick={toggleMenu}>
                {item.label}
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </Box>
  );

  return (
    <div>
      <AppBar component="nav" color="default" aria-label="navigation-menu">
        <Toolbar disableGutters className="h-18 bg-grey1">
          <div className="absolute md:relative w-full text-center pointer-events-none h-full flex justify-center md:w-fit md:px-8 py-2">
            <Button
              className="pointer-events-auto px-3"
              onClick={() => navigate(`${NavLinks.Home}`)}
              aria-label="ONCALL logo"
            >
              <img src={img} alt="ONCALL logo" />
            </Button>
          </div>

          <div
            className={
              'md:hidden px-4 h-full flex items-center ' +
              (open ? 'bg-grey8' : '')
            }
          >
            <a
              target="_blank"
              aria-label="Open menu"
              className={
                'flex ' + (open ? 'text-secondaryWhite' : 'text-secondaryBlack')
              }
              onClick={toggleMenu}
            >
              <MenuIcon className="text-lg mt-0.75" />
              <div className="ml-1 text-base">Menu</div>
            </a>
          </div>

          <div className="flex-grow"></div>

          <ul className="justify-start items-center hidden md:flex md:w-full">
            {menuItems.map((item: MenuItem) => (
              <li key={item.label} className="text-grey8 float-left block px-3">
                <Link to={item.url} onClick={toggleMenu}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>

          <div className="flex justify-end pr-2.5">
            <SignInSignOutButton />
          </div>
        </Toolbar>
      </AppBar>

      <Navigation />

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={toggleMenu}
          className="md:hidden w-88"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            slotProps: { backdrop: { invisible: true } },
          }}
          sx={{
            '& .MuiDrawer-paper': {
              color: 'white',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </div>
  );
}
