import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShiftStatus from '../ShiftStatus';

type Props = {
  status: string;
};

export default function ShiftStatusBadge({ status }: Props) {
  let statusClass = '';
  let iconClass = '';
  let icon: IconName = 'check';
  let size = 'text-xs';

  switch (status?.toUpperCase()) {
    case ShiftStatus.CONFIRMED:
      statusClass = ' w-28.5 h-7 text-xs border-primaryGreen text-black';
      iconClass = ' text-grey8';
      icon = 'check';
      break;
    case ShiftStatus.CANCELLED:
      statusClass = ' w-23 h-6 px-1.5 text-xxs border-red text-red';
      iconClass = ' text-red';
      icon = 'circle-xmark';
      break;
    case ShiftStatus.AVAILABLE:
      statusClass = ' w-27 h-7 text-xs border-primaryGreen text-black';
      iconClass = ' text-grey8';
      icon = 'square-plus';
      break;
    case ShiftStatus.SUBMITTED:
      statusClass = ' w-27 h-7 text-xs border-primaryGreen text-black';
      iconClass = ' text-black';
      icon = 'check';
      break;
    case ShiftStatus.PENDING:
      statusClass = ' w-27 h-7 text-xs border-primaryOrange text-black';
      iconClass = ' text-black';
      icon = 'warning';
      break;
    case ShiftStatus.FILLED:
      statusClass = ' w-27 h-7 text-xs border-primaryOrange text-black';
      iconClass = ' text-black';
      icon = 'exclamation';
      size = 'text-sm';
      break;
    case ShiftStatus.DUENOW:
      statusClass = ' w-27 h-7 text-xs border-primaryOrange text-black';
      iconClass = ' text-black';
      icon = 'clock';
      break;
    case ShiftStatus.OVERDUE:
      statusClass = ' w-27 h-7 text-xs border-red text-red';
      iconClass = ' text-red';
      icon = 'clock';
      break;
    default:
      statusClass = ' w-28.5 h-7 text-xs border-primaryGreen text-black';
      iconClass = ' text-grey8';
      icon = 'check';
      break;
  }

  function getStatus(status: string) {
    let statusString = status?.toUpperCase();

    switch (statusString) {
      case ShiftStatus.FILLED:
        statusString = 'PENDING';
    }

    return statusString;
  }

  return (
    <div
      className={
        'bg-secondaryWhite border-2 flex items-center justify-center font-bold rounded' +
        statusClass
      }
    >
      <div className={size + iconClass}>
        <FontAwesomeIcon icon={['fas', icon]} />
      </div>
      <div className="ml-1.5">{getStatus(status)}</div>
    </div>
  );
}
