import clsx from 'clsx';
import { useState } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';

export default function Checkbox({
  id,
  defaultChecked,
  disabled,
  register,
}: {
  id: string;
  defaultChecked: boolean;
  disabled: boolean;
  register: UseFormRegister<FieldValues>;
}) {
  const [checked, setChecked] = useState(defaultChecked);

  return (
    <input
      type="checkbox"
      className={clsx(
        `h-8 w-8 rounded border-2 text-primaryGreen`,
        checked ? 'border-primaryGreen' : 'border-grey5 bg-grey1'
      )}
      checked={checked}
      disabled={disabled}
      {...register(id, {
        onChange: (e) => setChecked(e.target.checked),
      })}
    />
  );
}
