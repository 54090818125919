import { format, set } from 'date-fns';
import { StartAndEndTime } from '../models';

export default function SleepoverAwakeTimes({
  startDate,
  endDate,
  awakeTimes,
}: {
  startDate: string;
  endDate: string;
  awakeTimes: StartAndEndTime[];
}) {
  return (
    <>
      {awakeTimes.length > 0 && (
        <div>
          <hr className="mt-5 mb-4 text-grey3" />
          <div className="font-bold mt-4">Sleepover awake times:</div>

          {awakeTimes.map((times: StartAndEndTime, i: number) => {
            const startTime = times.actualStartTime.split(':');
            const startTimeFmt = format(
              set(new Date(startDate), {
                hours: parseInt(startTime[0]),
                minutes: parseInt(startTime[1]),
              }),
              'p'
            );

            const endTime = times.actualEndTime.split(':');
            const endTimeFmt = format(
              set(new Date(endDate), {
                hours: parseInt(endTime[0]),
                minutes: parseInt(endTime[1]),
              }),
              'p'
            );

            return (
              <div
                className="flex justify-between items-center ml-3 mt-2 text-sm"
                key={i}
              >
                <span>Awake start:</span>
                <span className="ml-2 grow">{startTimeFmt}</span>

                <span>Awake end:</span>
                <span className="ml-2 grow">{endTimeFmt}</span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
