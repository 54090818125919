import { IconName } from '@fortawesome/fontawesome-svg-core';

export const iconForFacility: { [key: string]: IconName } = {
  Sleepover: 'bed',
  'Roster line': 'clock-rotate-left',
  'Active night': 'person-walking',
  Travel: 'road',
  'Participant transport': 'car',
  Expenses: 'dollar-sign',
  Break: 'mug-hot',
};

export const myShiftsRecordsToLoad = 6;

export const timeFormat = 'hh:mm A';
