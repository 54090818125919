import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@mui/material';

export default function ModalDialog(props: ModalDialogProps) {
  const { title, open, children, className, onClose } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <div className={className + ' overflow-y-hidden'}>
        <div className="font-bold px-3 py-3 flex items-center justify-between">
          <div>{title}</div>
          <FontAwesomeIcon
            icon={['fas', 'xmark']}
            className="mr-2 cursor-pointer"
            onClick={onClose}
          />
        </div>

        <hr className="text-grey4" />

        <div>{children}</div>
      </div>
    </Dialog>
  );
}

type ModalDialogProps = {
  title: JSX.Element;
  open: boolean;
  children: JSX.Element;
  className?: string;
  onClose?: () => void;
};
