import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';

import { msalConfig, tokenRequest } from '../../authConfig';

let msalInstance: PublicClientApplication;

export const getMsalInstance = (): PublicClientApplication => {
  if (msalInstance) {
    return msalInstance;
  }

  msalInstance = new PublicClientApplication(msalConfig);

  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const account = (event?.payload as AuthenticationResult).account;
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
};

export const acquireToken = async (
  msalInstance: PublicClientApplication,
  account: AccountInfo
) => {
  return await msalInstance
    .acquireTokenSilent({
      ...tokenRequest,
      account: account,
    })
    .then((response) => {
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === '') {
        throw new InteractionRequiredAuthError();
      }
      return response;
    })
    .catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        // Note: acquireTokenRedirect redirects the page,
        // any code that follows will not execute.
        await msalInstance.acquireTokenRedirect(tokenRequest);
      }
      throw error;
    });
};
