import TestId from '../TestId';
import { Shift } from '../models';

export const getRequirements = (shift: Shift): string[] => {
  const list: string[] = shift.shiftRequirements?.split(',') || [];
  return list.length && list[0] ? list : [];
};

export const hasRequirements = (shift: Shift): boolean =>
  getRequirements(shift)?.length > 0;

export default function RequirementBadges({ shift }: { shift: Shift }) {
  const requirements = getRequirements(shift);

  return !requirements.length ? (
    <></>
  ) : (
    <>
      <div className="font-bold">Requirements:</div>

      <div className="flex flex-wrap">
        {requirements?.map((req: string) => {
          return (
            <div
              className={
                'text-black text-xs font-bold px-2.5 py-1 border rounded-sm mr-2 mb-2 bg-secondaryYellow border-secondaryNavyBlue '
              }
              key={req}
              data-testid={TestId.ShiftRequirement}
            >
              {req?.toUpperCase()}
            </div>
          );
        })}
      </div>
    </>
  );
}
