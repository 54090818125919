type CountBadgeProps = {
  count: number;
  max?: number;
};

const DEFAULT_MAX = 10;

export default function CountBadge({ count, max }: CountBadgeProps) {
  return (
    <span className="inline-flex items-center rounded-lg bg-grey7 px-1.5 py-0.5 text-xs font-medium text-secondaryWhite">
      {count}
      {count >= (max ?? DEFAULT_MAX) && '+'}
    </span>
  );
}
