import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/base';
import { Link } from 'react-router-dom';

import { EntityType } from '../EntityType';
import TestId from '../TestId';
import { Shift } from '../models';
import { Timesheet } from '../models/Timesheet';
import Map from './Map';
import ShiftSummary from './ShiftSummary';

type Props = {
  entityType: EntityType;
  shift: Shift | Timesheet;
  message?: JSX.Element;
  children?: JSX.Element;
};

export default function ShiftDetail({
  shift,
  message,
  entityType,
  children,
}: Props) {
  return (
    <div>
      {message}
      <div
        className="flex flex-col md:flex-row justify-between"
        data-testid={TestId.Shift}
      >
        <div className="md:w-half">
          <ShiftSummary
            shiftData={shift}
            entityType={entityType}
            isOffer={entityType === EntityType.ShiftOffer}
            showDetails={true}
          />

          <div className="hidden md:block">{children}</div>
        </div>

        {entityType !== EntityType.Timesheet && (
          <div className="md:w-half border p-3 rounded bg-secondaryWhite border-secondaryShadow shadow mt-5 md:mt-0">
            <div className="flex justify-between items-center">
              <span className="text-md font-bold">Shift Location</span>
              <Link
                to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  shift.address
                )}`}
                target="_blank"
                rel="noopener noreferer"
              >
                <Button className="border px-4 py-1 text-secondaryWhite bg-secondaryBlack">
                  <span>Open in Maps</span>
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-up-right-from-square']}
                    className="ml-2"
                  />
                </Button>
              </Link>
            </div>
            <div className="mt-3">
              <Map address={shift.address} />
            </div>
          </div>
        )}
      </div>

      <div className="md:hidden">{children}</div>
    </div>
  );
}
