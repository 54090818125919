const FieldErrorMessage = ({
  message: errorMessage,
}: FieldErrorMessageProps) => (
  <p className="text-sm text-red">{errorMessage}</p>
);

type FieldErrorMessageProps = {
  message: string;
};

export default FieldErrorMessage;
