export default function Select({
  label,
  name,
  defaultValue,
  options,
  onChange,
}: {
  label: string;
  name: string;
  defaultValue: string;
  options: string[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}) {
  return (
    <div>
      <label
        htmlFor={name}
        className="inline w-full text-md text-left font-bold"
      >
        {label}
      </label>
      <select
        id={name}
        name={name}
        className="ml-2 inline text-secondaryBlack border-grey3 bg-grey1 text-sm p-2 pl-3 pr-7"
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </select>
    </div>
  );
}
