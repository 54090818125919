import { EntityType } from '../EntityType';

export const getEntityName = (entityType: EntityType) => {
  let entityName = '';

  switch (entityType) {
    case EntityType.MyShift:
      entityName = 'shift';
      break;
    case EntityType.ShiftOffer:
      entityName = 'shift offer';
      break;
    case EntityType.Timesheet:
      entityName = 'timesheet';
      break;
  }

  return entityName;
};
