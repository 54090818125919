import { useInfiniteQuery } from '@tanstack/react-query';

import { getTimesheets } from '../services/TimesheetApi';
import { EntityType } from '../shared/EntityType';
import { TimesheetTab } from '../shared/TimesheetTab';
import ShiftList from '../shared/components/ShiftList';
import { useHandleScroll } from '../shared/hooks/useHandleScroll';

type Props = {
  type: TimesheetTab;
  isVisible: boolean;
};

export default function TimesheetListTab({ type, isVisible: visible }: Props) {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['timesheets-' + type],
    queryFn: (params) => getTimesheets(type, params.pageParam),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  useHandleScroll(hasNextPage, fetchNextPage, isFetching);

  return !visible ? (
    <></>
  ) : (
    <ShiftList
      entityType={EntityType.Timesheet}
      data={data}
      error={error}
      hasNextPage={hasNextPage}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      status={status}
    />
  );
}
