import { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import { registerDevice } from '../../services/profileApi';
import { isPushApiSupported } from './usePushNotificationsSupported';

export default function useOneSignal() {
  const OneSignalAppId = process.env.REACT_APP_ONESIGNAL_APP_ID ?? '';
  const [oneSignalInitialized, setOneSignalInitialized] = useState(false);
  const [oneSignalUserId, setOneSignalUserId] = useState<
    string | null | undefined
  >(null);

  useEffect(() => {
    if (OneSignalAppId && isPushApiSupported()) {
      (async () => {
        await OneSignal.init({
          appId: OneSignalAppId,
          allowLocalhostAsSecureOrigin: true,
          serviceWorkerParam: { scope: '/push/onesignal/' },
          serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
        });

        OneSignal.on('subscriptionChange', async (isSubscribed) => {
          if (isSubscribed) {
            const id = await OneSignal.getUserId();
            if (id) {
              await registerDevice(id);
            }
          }
        });

        setOneSignalInitialized(true);
        setOneSignalUserId(await OneSignal.getUserId());
      })();
    }
  }, [OneSignalAppId]);

  return { oneSignalInitialized, oneSignalUserId };
}
