import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';

import LoadingSpinner from '../shared/components/LoadingSpinner';
import Title from '../shared/components/Title';
import { getInitials } from '../shared/util/getInitials';
import { profileDetailQuery } from '../shared/util/profileDetailQuery';

export default function Profile() {
  const { isLoading, isError, data: profile } = useQuery(profileDetailQuery());

  return (
    <div>
      <Title title="Profile" />

      {isLoading ? (
        <LoadingSpinner />
      ) : isError ? (
        <div className="messagebox w-full">
          Profile details are not available at this time.
        </div>
      ) : (
        <div className="content">
          <div className="flex">
            <div className="h-32 w-32 bg-grey border-grey6 bg-grey6 rounded-full flex items-center justify-center text-secondaryWhite text-4xl font-bold">
              {getInitials(profile.displayName)}
            </div>
            {/* <AccountCircle className="text-9xl text-grey6" /> */}
            <div className="p-6 flex flex-col justify-center">
              <h2 className="text-xl font-bold">{profile.displayName}</h2>
              <span className="mt-2">
                ONCALL ID: {profile.employeeId ?? ''}
              </span>
            </div>
          </div>

          <h2 className="text-lg font-bold mt-6">Contact Details:</h2>
          <div>
            {profile.mobilePhone && (
              <div className="my-3">
                <FontAwesomeIcon
                  className="text-primaryOrange"
                  icon={['fas', 'phone']}
                />
                <span className="ml-4">{profile.mobilePhone}</span>
              </div>
            )}
            {profile.mail && (
              <div className="my-3">
                <FontAwesomeIcon
                  className="text-primaryOrange"
                  icon={['fas', 'envelope']}
                />
                <span className="ml-4">{profile.mail}</span>
              </div>
            )}
            {profile.streetAddress &&
              profile.city &&
              profile.state &&
              profile.postalCode && (
                <div className="my-3">
                  <FontAwesomeIcon
                    className="text-primaryOrange"
                    icon={['fas', 'location-dot']}
                  />
                  <span className="ml-4">{`${profile.streetAddress}, ${profile.city}, ${profile.state} ${profile.postalCode}`}</span>
                </div>
              )}
          </div>
          <div className="text-grey8">
            Need to update your phone number, address, or other details? Contact
            your dedicated ONCALL Service Representative for assistance. They
            will ensure your information is accurately updated in our system.
          </div>
        </div>
      )}
    </div>
  );
}
