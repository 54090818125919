import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import App from './App';
import MyShiftsList from './pages/MyShiftsList';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import MyShiftDetails from './pages/MyShiftDetails';
import Profile from './pages/Profile';
import { getMsalInstance } from './shared/util/getMsalInstance';

import Availability, {
  availabilityAction,
  availabilityLoader,
} from './availability';
import AvailabilityError from './availability/Error';
import './index.css';
import Home from './pages/Home';
import MyShifts from './pages/MyShifts';
import Notifications, {
  NotificationsError,
  notificationsAction,
  notificationsLoader,
} from './pages/Notifications';
import ShiftOfferDetails from './pages/ShiftOfferDetails';
import ShiftOffers from './pages/ShiftOffers';
import ShiftOffersList from './pages/ShiftOffersList';
import TimesheetDetails from './pages/TimesheetDetails';
import TimesheetList from './pages/TimesheetList';
import Timesheets from './pages/Timesheets';
import NavLinks from './shared/NavLinks';
import { BreadCrumb } from './shared/components/Navigation';

const msalInstance = getMsalInstance();

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<App />}
      handle={{
        crumb: () => <BreadCrumb to="/" label="Home" />,
      }}
    >
      <Route index element={<Home />} />
      <Route
        path="profile"
        element={<Profile />}
        handle={{
          crumb: () => <BreadCrumb to={NavLinks.Profile} label="Profile" />,
        }}
      />
      <Route
        path="my-shifts"
        element={<MyShifts />}
        handle={{
          crumb: () => <BreadCrumb to={NavLinks.MyShifts} label="My Shifts" />,
        }}
      >
        <Route index element={<MyShiftsList />} />
        <Route
          path=":id"
          element={<MyShiftDetails />}
          handle={{
            crumb: () => <BreadCrumb label="Shift details" />,
          }}
        />
      </Route>
      <Route
        path="shift-offers"
        element={<ShiftOffers />}
        handle={{
          crumb: () => (
            <BreadCrumb to={NavLinks.ShiftOffers} label="Shift Offers" />
          ),
        }}
      >
        <Route index element={<ShiftOffersList />} />
        <Route
          path=":id"
          element={<ShiftOfferDetails />}
          handle={{
            crumb: () => <BreadCrumb label="Shift offer details" />,
          }}
        />
      </Route>
      <Route
        path="timesheets"
        element={<Timesheets />}
        handle={{
          crumb: () => (
            <BreadCrumb to={NavLinks.Timesheets} label="Timesheets" />
          ),
        }}
      >
        <Route index element={<TimesheetList />} />
        <Route
          path=":id"
          element={<TimesheetDetails />}
          handle={{
            crumb: () => <BreadCrumb label="Timesheet details" />,
          }}
        />
      </Route>
      <Route
        path="notifications"
        element={<Notifications />}
        loader={notificationsLoader}
        action={notificationsAction}
        errorElement={<NotificationsError />}
        handle={{
          crumb: () => (
            <BreadCrumb to={NavLinks.Notifications} label="Notifications" />
          ),
        }}
      />
      <Route
        path="availability"
        element={<Availability />}
        loader={availabilityLoader}
        action={availabilityAction}
        errorElement={<AvailabilityError />}
        handle={{
          crumb: () => (
            <BreadCrumb to={NavLinks.Availability} label="My Availability" />
          ),
        }}
      />
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
