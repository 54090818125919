import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact, { Coords } from 'google-map-react';
import { useState } from 'react';

export type MapProps = {
  address: string;
};

const zoomLevel = 15;

const defaultCenter: Coords = {
  lat: -37.8266424,
  lng: 145.0986946,
};

export default function Map({ address }: MapProps) {
  const [location, setLocation] = useState<Coords>(defaultCenter);

  return (
    <div className="w-full h-64 md:h-screen">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
        }}
        defaultCenter={defaultCenter}
        center={location}
        defaultZoom={zoomLevel}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ maps }) => {
          const geocoder = new maps.Geocoder();
          geocoder
            .geocode({ address })
            .then((result: { results: google.maps.GeocoderResult[] }) => {
              const { results } = result;
              const loc = results[0].geometry.location;
              if (result.results.length > 0) {
                setLocation({
                  lat: loc.lat(),
                  lng: loc.lng(),
                });
              }
            });
        }}
      >
        <Marker lat={location.lat} lng={location.lng} text={address} />
      </GoogleMapReact>
    </div>
  );
}

type MarkerProps = {
  lat: number;
  lng: number;
  text: string;
};

const Marker = ({ text }: MarkerProps) => {
  return (
    <div className="flex items-center w-48">
      <FontAwesomeIcon
        className="text-4xl text-red"
        icon={['fas', 'location-dot']}
      />
      <span className="shadow-text text-sm text-red ml-0.5">{text}</span>
    </div>
  );
};
