// TODO: Replace use of `data-testid` and getByTestId in favour of more semantic selectors
// https://testing-library.com/docs/queries/bytestid/
enum TestId {
  MyShifts = 'myshifts',
  ShiftOffers = 'shiftoffers',
  Shift = 'shift',
  ShiftRequirement = 'shift-requirement',
  Timesheets = 'timesheets',
  Allowance = 'shift-allowance',
  Document = 'shift-document',
  ShiftID = 'shift-id',
  AcceptShiftButton = 'accept-shift-btn',
  RejectShiftButton = 'reject-shift-btn',
  RejectShiftDialog = 'reject-shift-dialog',
  SubmitTimesheetButton = 'submit-timesheet-btn',
  ChangeTimesheetButton = 'change-timesheet-btn',
  TimesheetMakeChangesDateRange = 'timesheet-make-changes-date-range',
  TimesheetAddAwakeTime = 'timesheet-add-awake-time',
  TimesheetSubmitChanges = 'timesheet-submit-changes',
  ParticipantTransportDistance = 'participant-transport-distance',
}

export default TestId;
