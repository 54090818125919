import { FieldValues, UseFormRegister } from 'react-hook-form';

export default function TimeInput({
  id,
  value,
  min,
  max,
  disabled,
  onChange,
  register,
}: {
  id: string;
  value: string;
  min: string;
  max: string;
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  register: UseFormRegister<FieldValues>;
}) {
  return (
    <input
      type="time"
      className="bg-grey1 border-grey3 text-sm text-secondaryBlack p-1 max-w-[76px]"
      required
      defaultValue={value}
      min={min}
      max={max}
      disabled={disabled}
      {...register(id, {
        onBlur: onChange,
      })}
    />
  );
}
