import ky from 'ky';

import { acquireToken, getMsalInstance } from '../shared/util/getMsalInstance';

const getApiRequestObject = async () => {
  const msalInstance = getMsalInstance();
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await acquireToken(msalInstance, account);

  return ky.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set(
            'Authorization',
            `Bearer ${response.accessToken}`
          );
          request.headers.set(
            'Ocp-Apim-Subscription-Key',
            `${process.env.REACT_APP_APIM_SUBSCRIPTION_KEY}`
          );
          request.headers.set(
            'Access-Control-Allow-Origin',
            window.location.origin
          );
        },
      ],
    },
  });
};

export async function queryApi(path: string) {
  const api = await getApiRequestObject();
  return await api.get(`${process.env.REACT_APP_API_ENDPOINT}${path}`);
}

export async function postApi(path: string, body: BodyInit) {
  const api = await getApiRequestObject();
  return await api.post(`${process.env.REACT_APP_API_ENDPOINT}${path}`, {
    body,
  });
}
