import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router';
import { redirect, useNavigate } from 'react-router-dom';

import { ButtonBase } from '@mui/material';
import { acceptOrDeclineShiftOffer, getShiftOffer } from '../services/ShiftApi';
import { EntityType } from '../shared/EntityType';
import NavLinks from '../shared/NavLinks';
import TestId from '../shared/TestId';
import ConfirmDialog, { DialogType } from '../shared/components/ConfirmDialog';
import LoadingSpinner from '../shared/components/LoadingSpinner';
import ShiftDetail from '../shared/components/ShiftDetail';
import { SpinnerIcon } from '../shared/components/SpinnerIcon';
import Title from '../shared/components/Title';

const shiftOfferDetailsQuery = (id: number) => ({
  queryKey: ['shiftoffer', id],
  queryFn: async () => getShiftOffer(id),
  refetchOnWindowFocus: false,
  retry: false,
});

export default function ShiftOfferDetails() {
  const { id } = useParams();
  if (!id) {
    throw redirect(NavLinks.ShiftOffers);
  }

  const shiftOfferId = parseInt(id);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [showRejecting, setShowRejecting] = useState<boolean>(false);

  const { isLoading, isError, data } = useQuery(
    shiftOfferDetailsQuery(shiftOfferId)
  );

  const {
    data: acceptResponse,
    mutate: acceptShift,
    isLoading: isAccepting,
    isError: isErrorAccepting,
  } = useMutation({
    mutationFn: () => {
      return acceptOrDeclineShiftOffer(shiftOfferId, true);
    },
    onSuccess: (resp) => {
      queryClient.invalidateQueries({ queryKey: ['shiftOffers'] });

      if (resp.SuccessCodeID && resp.SuccessCodeID === 1) {
        navigate(`${NavLinks.MyShifts}/${data?.shiftID}?accepted=true`);
      }
    },
  });

  // The accept shift offer API returns a 200 OK response even if there
  // the shift could not be accepted due to business rules.
  // We need to check the response to see if the shift was accepted.
  const wasNotAccepted =
    acceptResponse !== undefined && acceptResponse.SuccessCodeID !== 1;

  const {
    data: rejectResponse,
    mutate: rejectShift,
    isLoading: isRejecting,
    isError: isErrorRejecting,
  } = useMutation({
    mutationFn: () => {
      return acceptOrDeclineShiftOffer(shiftOfferId, false);
    },
    onSuccess: (resp) => {
      queryClient.invalidateQueries({ queryKey: ['shiftOffers'] });

      if (resp.SuccessCodeID && resp.SuccessCodeID === 1) {
        navigate(`${NavLinks.ShiftOffers}/?declined=true`);
      }
    },
  });

  const isDisabled = isAccepting || isRejecting;

  return (
    <>
      <Title title="Shift offer details" />

      {isLoading ? (
        <LoadingSpinner />
      ) : isError ? (
        <div className="messagebox w-full">
          Details for this shift offer are not available at this time.
        </div>
      ) : (
        <ShiftDetail entityType={EntityType.ShiftOffer} shift={data}>
          <div className="mt-5">
            <div
              className={
                'flex space-x-2 sm:space-x-6' +
                (isDisabled ? ' opacity-60' : '')
              }
            >
              <ButtonBase
                className="border border-solid border-primaryOrange text-md bg-secondaryWhite h-11 px-2 sm:px-8 py-2.5 grow"
                onClick={() => setShowRejecting(true)}
                disabled={isAccepting || isRejecting}
                data-testid={TestId.RejectShiftButton}
              >
                <SpinnerIcon
                  isLoading={isRejecting}
                  staticIcon="xmark"
                  className="mr-2"
                />
                <span>Decline Shift</span>
              </ButtonBase>

              <ButtonBase
                className="border border-solid border-green text-md bg-secondaryWhite h-11 px-2 sm:px-8 py-2.5 grow"
                onClick={() => acceptShift()}
                disabled={isDisabled}
                data-testid={TestId.AcceptShiftButton}
              >
                <SpinnerIcon
                  isLoading={isAccepting}
                  staticIcon="check"
                  className="mr-2"
                />
                <span>Accept Shift</span>
              </ButtonBase>
            </div>

            {(isErrorAccepting || isErrorRejecting) && (
              <div className="mt-5 text-red">Sorry, an error has occurred.</div>
            )}

            <div className="mt-5">
              Please note: The ‘Decline Shift’ button will remove the shift from
              your list of Shift Offers as well as let ONCALL know not to call
              you about this shift.
            </div>

            <ConfirmDialog
              title={<>Shift could not be accepted</>}
              body={
                acceptResponse?.Message ?? 'An unspecified error has occurred'
              }
              open={wasNotAccepted}
              onClose={() => {
                navigate(NavLinks.ShiftOffers);
              }}
            />

            <ConfirmDialog
              title={<>Decline shift</>}
              body="Please confirm that you want to decline this shift."
              open={showRejecting}
              type={DialogType.ConfirmOrCancel}
              errorMessage={
                rejectResponse?.Message ?? 'An unspecified error has occurred'
              }
              data-testid={TestId.RejectShiftDialog}
              isProcessing={isRejecting}
              onConfirm={rejectShift}
              onCancel={() => setShowRejecting(false)}
              onClose={() => setShowRejecting(false)}
            />
          </div>
        </ShiftDetail>
      )}
    </>
  );
}
