import { Shift } from '../shared/models';
import { postApi, queryApi } from './api';

const MY_SHIFTS_PAGE_SIZE = 10;
const SHIFT_OFFERS_PAGE_SIZE = 10;

export const getMyShifts = async ({ pageParam = 0 }) => {
  const resp = await queryApi(
    `/shifts/my?StartPage=${pageParam + 1}&PageSize=${MY_SHIFTS_PAGE_SIZE}`
  );

  const shifts = (await resp.json()) as Shift[];

  return Promise.resolve(
    shifts.length === MY_SHIFTS_PAGE_SIZE
      ? {
          data: shifts,
          nextPage: pageParam + 1,
        }
      : {
          data: shifts,
          // Only include nextPage if there are more results
        }
  );
};

export const getShift = async (id: number) => {
  const apiResponse = await queryApi(`/shifts/my/${id}`);

  return (await apiResponse.json()) as Shift;
};

export const getShiftOffers = async ({ pageParam = 0 }) => {
  const resp = await queryApi(
    `/shiftoffers/my?StartPage=${
      pageParam + 1
    }&PageSize=${SHIFT_OFFERS_PAGE_SIZE}`
  );

  const shifts = (await resp.json()) as Shift[];
  return Promise.resolve(
    shifts.length === SHIFT_OFFERS_PAGE_SIZE
      ? {
          data: shifts,
          nextPage: pageParam + 1,
        }
      : {
          data: shifts,
          // Only include nextPage if there are more results
        }
  );
};

export const getShiftOffer = async (id: number) => {
  const apiResponse = await queryApi(`/shiftoffers/my/${id}`);

  return (await apiResponse.json()) as Shift;
};

export const acceptOrDeclineShiftOffer = async (
  id: number,
  accept: boolean
) => {
  const body = {
    Status: accept ? 'Accept' : 'Decline',
  };
  const apiResponse = await postApi(
    `/shiftoffers/my/${id}`,
    JSON.stringify(body)
  );

  return (await apiResponse.json()) as {
    Message: string;
    SuccessCodeID: number;
  };
};
