import BackNavButton from './BackNavButton';

export default function Title(props: { title: string }) {
  const { title } = props;

  return (
    <div className="my-5">
      <div className="absolute z-1 md:hidden">
        <BackNavButton />
      </div>
      <h1 className="text-2xl md:text-3xl font-bold text-center">{title}</h1>
    </div>
  );
}
