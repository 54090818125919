enum ShiftStatus {
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  AVAILABLE = 'AVAILABLE',
  SUBMITTED = 'SUBMITTED',
  DUENOW = 'DUE NOW',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  FILLED = 'FILLED',
}

export default ShiftStatus;
