import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TestId from '../TestId';
import { iconForFacility } from '../constants';

export const getTextWithIcon = (text: string, suffix = '') => {
  const icon: IconName = Object.prototype.hasOwnProperty.call(
    iconForFacility,
    text
  )
    ? iconForFacility[text]
    : 'warning';

  return (
    <div className="icon-text flex" key={text} data-testid={TestId.Allowance}>
      <div className="w-5 text-center">
        <FontAwesomeIcon icon={['fas', icon]} size="sm" />
      </div>
      <span>{text + (suffix ? ' (' + suffix + ')' : '')}</span>
    </div>
  );
};
