import FieldErrorMessage from './FieldErrorMessage';

export default function Input(
  {
    fieldName,
    label,
    register,
    options,
    className,
    fieldClassName,
    errorMessage = '',
    errorClassName = '',
    errors,
    ...props
  }: any // eslint-disable-line @typescript-eslint/no-explicit-any
) {
  return (
    <div className="flex flex-col grow">
      <div>
        {label && <label htmlFor={fieldName}>{label}</label>}
        <span className={className}>
          <input
            id={fieldName}
            {...register(fieldName, options)}
            className={
              'w-12 border border-1 border-grey3 bg-grey1 py-1 px-2 text-sm ' +
              fieldClassName
            }
            {...props}
          />
        </span>
      </div>
      <div className={errorClassName}>
        {errors?.[fieldName]?.type === 'required' && (
          <FieldErrorMessage message={errors?.[fieldName]?.message} />
        )}
        {errorMessage && <FieldErrorMessage message={errorMessage} />}
      </div>
    </div>
  );
}
