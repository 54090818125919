import { ChangeEventHandler } from 'react';

export type CheckboxProps = {
  id: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export default function Checkbox({
  id,
  checked,
  disabled,
  onChange,
}: CheckboxProps) {
  return (
    <input
      id={id}
      name={id}
      type="checkbox"
      className={`h-11 w-11 rounded ${
        checked ? 'border-primaryGreen' : 'border-secondaryGrey'
      } border-2 text-primaryGreen`}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  );
}
