import { Configuration, LogLevel, PopupRequest } from '@azure/msal-browser';

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const b2cTenant = process.env.REACT_APP_B2C_TENANT || 'oncalldev';
/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    changePassword: 'B2C_1A_PASSWORDCHANGE',
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${b2cTenant}.b2clogin.com/${b2cTenant}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`,
    },
    changePassword: {
      authority: `https://${b2cTenant}.b2clogin.com/${b2cTenant}.onmicrosoft.com/B2C_1A_PASSWORDCHANGE`,
    },
  },
  authorityDomain: `${b2cTenant}.b2clogin.com`,
};

// Config object to be passed to Msal on creationexport const msalConfig: Configuration = {
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const b2cScopes = [
  `https://${b2cTenant}.onmicrosoft.com/apim/SupportWorker.All`,
];

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['openid', ...b2cScopes],
};

export const tokenRequest = {
  scopes: [...b2cScopes],
};
