import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase } from '@mui/material';
import { format, parse } from 'date-fns';
import moment, { Moment } from 'moment';
import {
  FieldArrayWithId,
  FieldErrors,
  SubmitHandler,
  UseFieldArrayRemove,
  UseFormRegister,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import { FocusEvent, useEffect, useState } from 'react';
import TestId from '../shared/TestId';
import FieldErrorMessage from '../shared/components/FieldErrorMessage';
import Input from '../shared/components/Input';
import ModalDialog from '../shared/components/ModalDialog';
import RedAsterisk from '../shared/components/RedAsterisk';
import { iconForFacility, timeFormat } from '../shared/constants';
import { StartAndEndTime } from '../shared/models';
import { Timesheet } from '../shared/models/Timesheet';
import { getMomentFromDateAndTime } from '../shared/util/getMomentFromDateAndTime';
import { getTextWithIcon } from '../shared/util/getTextWithIcon';

export default function TimesheetEdit({
  timesheet,
  open,
  onSave,
  onClose,
}: TimesheetEditProps) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'actualSleepoverActiveHours',
  });

  const [actualStartDate, setActualStartDate] = useState<Moment>(moment());
  const [actualEndDate, setActualEndDate] = useState<Moment>(moment());

  useEffect(() => {
    resetTimesheet();
    setActualStartDate(moment(timesheet.startDate));
    setActualEndDate(moment(timesheet.endDate));
  }, [timesheet]);

  const resetTimesheet = () => {
    const actualStartTime = moment(timesheet.actualStartDate).format('HH:mm');
    const actualEndTime = moment(timesheet.actualEndDate).format('HH:mm');

    reset({
      actualBreakTimeInMinutes: timesheet.actualBreakTimeInMinutes,
      participantTransportDistanceInKm:
        timesheet.participantTransportDistanceInKm,
      actualStartTime,
      actualEndTime,
      actualParticipantTransportDistanceInKm:
        timesheet.actualParticipantTransportDistanceInKm,
      reason: timesheet.reason,
    });
  };

  const title = (
    <div className="text-md font-bold">
      <FontAwesomeIcon icon={['fas', 'pencil']} className="mr-3" />
      <span>Make changes</span>
    </div>
  );

  const breakOptions = (
    <>
      {Array.from([0, 15, 30, 45, 60, 90], (x, i) => {
        return (
          <option key={i} value={x}>
            {x}
          </option>
        );
      })}
    </>
  );

  const onSubmit: SubmitHandler<FormValues> = async (values: FormValues) => {
    const actualStartDate = getMomentFromDateAndTime(
      timesheet.startDate,
      values.actualStartTime
    ).toISOString();

    const actualEndDate = getMomentFromDateAndTime(
      timesheet.endDate,
      values.actualEndTime
    ).toISOString();

    values.actualSleepoverActiveHours?.forEach((times: StartAndEndTime) => {
      const startDate = getMomentFromDateAndTime(
        timesheet.startDate,
        times.actualStartTime
      );

      const endDate = getMomentFromDateAndTime(
        timesheet.endDate,
        times.actualStartTime
      );

      if (endDate.diff(startDate) < 0) {
        endDate.add(1, 'days');
      }

      return endDate.diff(startDate, 'minutes');
    });

    const timesheetData: Timesheet = {
      ...timesheet,
      actualStartDate,
      actualEndDate,
      actualBreakTimeInMinutes: values.actualBreakTimeInMinutes,
      actualSleepoverActiveHours: values.actualSleepoverActiveHours ?? [],
      actualParticipantTransportDistanceInKm:
        values.actualParticipantTransportDistanceInKm,
      reason: values.reason,
    };

    return onSave(timesheetData);
  };

  const updateActualEndDate = (field: 'start' | 'end', value: string) => {
    let actualStartTime: string;
    let actualEndTime: string;

    if (field === 'end') {
      actualStartTime = actualStartDate?.format('HH:mm');
      actualEndTime = value;
    } else {
      actualStartTime = value;
      actualEndTime = actualEndDate?.format('HH:mm');
    }

    const actualStartDate2 = getMomentFromDateAndTime(
      timesheet.actualStartDate,
      actualStartTime
    );

    const actualEndDate2 = getMomentFromDateAndTime(
      timesheet.actualStartDate,
      actualEndTime
    );

    if (actualEndTime <= actualStartTime) {
      actualEndDate2.add(1, 'days');
    }

    setActualStartDate(actualStartDate2);
    setActualEndDate(actualEndDate2);
  };

  return (
    <form>
      <ModalDialog
        title={title}
        open={open}
        className="max-w-modal h-full"
        onClose={() => {
          resetTimesheet();
          onClose();
        }}
      >
        <div className="text-sm px-4 mb-3 overflow-y-scroll h-modal pb-24">
          <div className="flex justify-between mt-5">
            <span
              className="text-base font-bold"
              data-testid={TestId.TimesheetMakeChangesDateRange}
            >
              {actualStartDate?.format('ddd D MMM')}

              {actualEndDate?.format('ddd D MMM') !==
              actualStartDate?.format('ddd D MMM') // check if shift start and end actual day matches
                ? ' - ' + `${actualEndDate?.format('ddd D MMM')}`
                : ''}
            </span>

            <span>
              <RedAsterisk /> required field
            </span>
          </div>

          <div className="flex mt-4.5 justify-between">
            <span>Original start:</span>
            <span className="ml-2 grow">
              {moment(timesheet.startDate).format(timeFormat)}
            </span>
            <span>Original end:</span>&nbsp;
            <span className="ml-2 grow">
              {moment(timesheet.endDate).format(timeFormat)}
            </span>
          </div>

          <div className="flex mt-4.5">
            <Input
              fieldName="actualStartTime"
              label={
                <span className="ml-1">
                  Actual start
                  <RedAsterisk />:
                </span>
              }
              register={register}
              options={{
                required: {
                  value: true,
                  message: 'Actual start is required',
                },
              }}
              errors={errors}
              type="time"
              className="ml-1"
              fieldClassName="w-20"
              onBlur={(e: FocusEvent<HTMLInputElement>) =>
                updateActualEndDate('start', e.target.value)
              }
            />

            <Input
              fieldName="actualEndTime"
              label={
                <span className="ml-4">
                  Actual End
                  <RedAsterisk />:
                </span>
              }
              register={register}
              errorClassName="w-36"
              options={{
                required: {
                  value: true,
                  message: 'Actual end is required',
                },
              }}
              errors={errors}
              type="time"
              className="ml-1 grow"
              fieldClassName="w-20"
              onBlur={(e: FocusEvent<HTMLInputElement>) =>
                updateActualEndDate('end', e.target.value)
              }
            />
          </div>

          <div className="mt-4.5 pl-4">
            <FontAwesomeIcon icon={['fas', 'mug-hot']} className="mr-3" />
            <label htmlFor="id">Break:</label>
            <select
              title="break"
              id="break"
              className="w-15 border-1 border-grey3 bg-grey1 py-1 px-2 text-sm ml-2"
              defaultValue={timesheet.breakTimeInMinutes}
              {...register('actualBreakTimeInMinutes')}
            >
              {breakOptions}
            </select>
            <span className="ml-1">mins</span>
            <span className="ml-2 grow">
              ({timesheet.breakTimeInMinutes} mins. max.)
            </span>
          </div>

          {timesheet.sleepOver && (
            <>
              <hr className="mt-6 border-grey3" />

              <div className="flex justify-between mt-5">
                <span className="text-base font-bold">
                  Sleepover awake times
                </span>

                <div key="sleepover">
                  <FontAwesomeIcon
                    icon={['fas', iconForFacility['Sleepover']]}
                    size="sm"
                  />
                  &ensp;
                  <span>
                    {'Sleepover ' +
                      (timesheet.sleepoverStartAndEndTime
                        ? `(${timesheet.sleepoverStartAndEndTime})`
                        : '')}
                  </span>
                </div>
              </div>

              <div className="mt-4">
                If you were woken up to work during your sleepover, use the 'Add
                awake times' button below to add the times that you needed to be
                awake during the sleepover part of your shift.
              </div>

              <AwakeTimes
                fields={fields}
                remove={remove}
                register={register}
                errors={errors}
                sleepoverStartTime={convertTimeHHmm(
                  timesheet.sleepoverStartTime
                )}
                sleepoverEndTime={convertTimeHHmm(timesheet.sleepoverEndTime)}
              />

              <ButtonBase
                className="mt-4 py-1 px-3 text-secondaryBlack bg-secondaryWhite font-normal shadow-button border border-solid border-primaryOrange text-base"
                data-testid={TestId.TimesheetAddAwakeTime}
                onClick={() =>
                  append({
                    actualStartTime: '',
                    actualEndTime: '',
                  })
                }
              >
                <FontAwesomeIcon
                  icon={['far', 'square-plus']}
                  className="mr-2"
                />
                <span>Add awake times</span>
              </ButtonBase>
            </>
          )}

          {timesheet.participantTransportDistanceInKm > 0 && ( // the only editable allowance currently is the 'participantTransportDistanceInKm' field
            <>
              <hr className="mt-6 border-grey3" />

              <div
                className="flex justify-between mt-5 items-center"
                data-testid={TestId.ParticipantTransportDistance}
              >
                <div>
                  <span className="text-base font-bold">Allowances</span>
                  <div className="mt-2">
                    {getTextWithIcon(
                      'Participant transport',
                      timesheet.participantTransportDistanceInKm + 'km max.'
                    )}
                  </div>
                </div>

                <div>
                  <span className="text-base font-bold">Actual</span>
                  <span className="flex">
                    <Input
                      register={register}
                      fieldName="actualParticipantTransportDistanceInKm"
                      className="mt-2 w-12"
                      type="number"
                    />
                    &nbsp;km
                  </span>
                </div>
              </div>
            </>
          )}

          <hr className="mt-6 border-grey3" />

          <div className="mt-5">
            <label htmlFor="reason" className="text-base font-bold">
              Reason
              <RedAsterisk />:
            </label>
            &nbsp; Please explain any changes.
            <textarea
              id="reason"
              {...register('reason', { required: true })}
              className="mt-2 w-full h-24 border border-1 border-grey3 bg-grey1 resize-none"
            />
            {errors?.reason?.type === 'required' && (
              <FieldErrorMessage message="'Reason' is required" />
            )}
          </div>

          <div className="flex justify-between text-md mt-6">
            <ButtonBase
              className="w-30 h-11 text-secondaryWhite bg-secondaryBlack font-normal shadow-button"
              onClick={onClose}
            >
              <span>Cancel</span>
            </ButtonBase>

            <ButtonBase
              className="w-35 h-11 text-secondaryBlack bg-secondaryWhite font-normal shadow-button border border-solid border-primaryGreen"
              data-testid={TestId.TimesheetSubmitChanges}
              onClick={handleSubmit(onSubmit)}
            >
              <FontAwesomeIcon icon={['fas', 'save']} className="mr-4" />
              <span>Save</span>
            </ButtonBase>
          </div>
        </div>
      </ModalDialog>
    </form>
  );
}

const AwakeTimes = ({
  fields,
  remove,
  register,
  errors,
  sleepoverStartTime,
  sleepoverEndTime,
}: {
  fields: FieldArrayWithId<FormValues>[];
  remove: UseFieldArrayRemove;
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
  sleepoverStartTime: string;
  sleepoverEndTime: string;
}) => {
  const deleteAwakeTime = (i: number) => {
    remove(i);
  };

  return (
    <div>
      {fields.map((times: StartAndEndTime, i: number) => {
        return (
          <fieldset key={'fieldset_' + i} className="mt-2">
            <div className="flex justify-between items-start">
              <AwakeTimeInput
                register={register}
                startOrEnd="start"
                errors={errors}
                index={i}
                sleepoverStartTime={sleepoverStartTime}
                sleepoverEndTime={sleepoverEndTime}
              />

              <AwakeTimeInput
                register={register}
                startOrEnd="end"
                errors={errors}
                index={i}
                sleepoverStartTime={sleepoverStartTime}
                sleepoverEndTime={sleepoverEndTime}
              />

              <FontAwesomeIcon
                icon={['fas', 'xmark']}
                size="lg"
                className="mt-1.5 mr-2 cursor-pointer text-grey6"
                onClick={() => deleteAwakeTime(i)}
              />
            </div>
          </fieldset>
        );
      })}
    </div>
  );
};

const AwakeTimeInput = ({
  register,
  startOrEnd,
  errors,
  index,
  sleepoverStartTime,
  sleepoverEndTime,
}: {
  register: UseFormRegister<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  startOrEnd: 'start' | 'end';
  errors: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  index: number;
  sleepoverStartTime: string;
  sleepoverEndTime: string;
}) => {
  let fieldName: string;
  let label: string;

  if (startOrEnd === 'start') {
    fieldName = 'actualStartTime';
    label = 'Awake start';
  } else {
    fieldName = 'actualEndTime';
    label = 'Awake end';
  }

  const id = `${fieldName}_${index}`;

  return (
    <div className="flex flex-col">
      <span className="flex align-center">
        <label htmlFor={id} className="mt-1">
          {label}
          <RedAsterisk />:
        </label>

        <input
          id={id}
          type="time"
          step="900"
          className="w-20 border border-1 border-grey3 bg-grey1 text-sm py-1 px-2 ml-1"
          {...register(`actualSleepoverActiveHours.${index}.${fieldName}`, {
            required: `${label} is required`,
            validate: {
              // Assumes sleepover period crosses over midnight
              withinSleepover: (value) =>
                value >= sleepoverStartTime ||
                value <= sleepoverEndTime ||
                'Must be within sleepover period',
            },
          })}
        />
      </span>

      {errors?.actualSleepoverActiveHours?.length > 0 && (
        <div className="w-36">
          {errors?.actualSleepoverActiveHours[index]?.[fieldName]?.message && (
            <FieldErrorMessage
              message={
                errors?.actualSleepoverActiveHours[index]?.[fieldName]?.message
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

type TimesheetEditProps = {
  timesheet: Timesheet;
  open: boolean;
  onSave: (data: Timesheet) => void;
  onClose: () => void;
};

export type FormValues = {
  actualStartTime: string;
  actualEndTime: string;
  actualBreakTimeInMinutes: number;
  participantTransportDistanceInKm: number;
  actualParticipantTransportDistanceInKm: number;
  actualSleepoverActiveHours?: StartAndEndTime[];
  reason: string;
};

const convertTimeHHmm = (time: string) =>
  format(parse(time, 'HH:mm:ss', new Date()), 'HH:mm');
