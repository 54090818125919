import { CircularProgress } from '@mui/material';
import OneSignal from 'react-onesignal';
import {
  ActionFunctionArgs,
  LoaderFunction,
  isRouteErrorResponse,
  redirect,
  useFetcher,
  useLoaderData,
  useNavigation,
  useRouteError,
} from 'react-router-dom';
import { isRegistered, isSubscribed } from '../services/profileApi';
import NavLinks from '../shared/NavLinks';
import Checkbox from '../shared/components/Checkbox';
import Title from '../shared/components/Title';
import {
  areOneSignalScriptsLoaded,
  isPushApiSupported,
} from '../shared/hooks/usePushNotificationsSupported';

export const notificationsLoader = (async () => {
  const pushNotificationsSupported =
    areOneSignalScriptsLoaded() && isPushApiSupported();
  let pushNotificationsEnabled = false;

  if (pushNotificationsSupported) {
    if (window.location.hostname === 'localhost') {
      pushNotificationsEnabled = await OneSignal.isPushNotificationsEnabled();
    } else {
      const userId = await OneSignal.getUserId();
      if (userId) {
        pushNotificationsEnabled =
          (await isSubscribed(userId)) && (await isRegistered(userId));
      }
    }
  }

  return { pushNotificationsSupported, pushNotificationsEnabled };
}) satisfies LoaderFunction;

const notificationsCheckboxId = 'notifications-enabled';

export const notificationsAction = async ({ request }: ActionFunctionArgs) => {
  if (areOneSignalScriptsLoaded()) {
    const formData = await request.formData();
    if (formData.get(notificationsCheckboxId) === 'on') {
      await OneSignal.registerForPushNotifications();
    } else {
      await OneSignal.setSubscription(false);
    }
  }

  return redirect(`${NavLinks.Notifications}`);
};

export default function Notifications() {
  const { pushNotificationsSupported, pushNotificationsEnabled } =
    useLoaderData() as Awaited<ReturnType<typeof notificationsLoader>>;
  const navigation = useNavigation();
  const busy =
    navigation.state === 'loading' || navigation.state === 'submitting';

  const toggle = useFetcher();
  const checked = toggle.formData
    ? toggle.formData.get(notificationsCheckboxId) === 'on'
    : pushNotificationsEnabled;

  return (
    <div>
      <Title title="Notifications" />

      {!pushNotificationsSupported ? (
        <div className="messagebox w-full">
          <p className="pb-2">
            Your current device settings and web browser do not support Push
            Notifications.
          </p>
          <p>
            Please view the{' '}
            <a
              className="text-primaryGreen"
              href="https://oncall.atlassian.net/servicedesk/customer/portal/17/topic/30b4809f-0b04-4b14-b5b3-69070434cf79/article/119111704"
              target="_blank"
            >
              myONCALL Help Centre
            </a>{' '}
            for details on the web browsers and settings that support Push
            Notifications.
          </p>
        </div>
      ) : (
        <div className="content">
          <div className="flex">
            <div className="justify-top">
              {busy ? (
                <CircularProgress
                  className="h-11 w-11 text-secondaryBlack"
                  aria-label="Loading notifications status"
                />
              ) : (
                <toggle.Form method="post" action={`${NavLinks.Notifications}`}>
                  <Checkbox
                    id={notificationsCheckboxId}
                    checked={checked}
                    disabled={busy}
                    onChange={(e) => toggle.submit(e.target.form)}
                  />
                </toggle.Form>
              )}
            </div>
            <div className="pt-1 pl-6 pb-4 flex flex-col justify-top">
              <label
                htmlFor={notificationsCheckboxId}
                className="text-xl font-bold"
              >
                Push Notifications
              </label>
              <span className="mt-2 text-grey8">
                Get a push notification on your device when shifts are offered
                to you.
              </span>
            </div>
          </div>
          <div className="text-grey8">
            <span className="font-bold">Note:</span>&nbsp;Notifications are
            activated per device. To receive notifications on multiple devices,
            sign in on each device, enable notifications, and make sure you're
            online.
          </div>
        </div>
      )}
    </div>
  );
}

export function NotificationsError() {
  const error = useRouteError();
  console.error(JSON.stringify(error));

  return (
    <>
      {isRouteErrorResponse(error) ? (
        <div>
          <Title title="Notifications" />
          <div className="messagebox w-full">
            Notifications status could not be changed at this time.
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
