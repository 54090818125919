import { Shift } from '../models';
import { Timesheet } from '../models/Timesheet';
import { getTextWithIcon } from '../util/getTextWithIcon';

const conditions = (shift: Shift | Timesheet): boolean[] => [
  shift.sleepOver,
  shift.toFromHoursAllowance > 0 || shift.toFromDistanceAllowanceInKm > 0,
  shift.participantTransportDistanceInKm > 0 ||
    (shift as Timesheet).actualParticipantTransportDistanceInKm > 0,
  shift.expenses > 0,
];

export const hasAllowances = (shift: Shift | Timesheet): boolean =>
  conditions(shift).reduce((prev: boolean, curr: boolean) => prev || curr);

export const allowanceCount = (shift: Shift | Timesheet): number =>
  conditions(shift).filter((c: boolean) => c).length;

export default function AllowanceBadges({
  shift,
}: {
  shift: Shift | Timesheet;
}) {
  const flag = hasAllowances(shift);

  return !flag ? (
    <></>
  ) : (
    <>
      <div className="font-bold mt-4">Allowances:</div>
      {shift.sleepOver &&
        getTextWithIcon('Sleepover', shift.sleepoverStartAndEndTime)}

      {(shift.toFromDistanceAllowanceInKm > 0 ||
        shift.toFromHoursAllowance > 0) &&
        getTextWithIcon(
          'Travel',
          shift.toFromDistanceAllowanceInKm > 0 &&
            shift.toFromHoursAllowance > 0
            ? `${shift.toFromDistanceAllowanceInKm}km & ${shift.toFromHoursAllowance} hours`
            : shift.toFromDistanceAllowanceInKm > 0
            ? `${shift.toFromDistanceAllowanceInKm}km`
            : `${shift.toFromHoursAllowance} hours`
        )}

      {(shift.participantTransportDistanceInKm > 0 ||
        (shift as Timesheet).actualParticipantTransportDistanceInKm > 0) && (
        <span className="flex">
          {getTextWithIcon(
            'Participant transport',
            shift.participantTransportDistanceInKm + 'km max.'
          )}
          {(shift as Timesheet).actualParticipantTransportDistanceInKm > 0 ? (
            <>
              :&nbsp;
              <span className="text-red">
                {(shift as Timesheet).actualParticipantTransportDistanceInKm}
                km
              </span>
            </>
          ) : (
            ''
          )}
        </span>
      )}

      {shift.expenses > 0 && getTextWithIcon('Expenses', '$' + shift.expenses)}
    </>
  );
}
