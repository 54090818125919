import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { add, format, parse, sub } from 'date-fns';
import { useState } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { AVAILABILITY_API_DATE_FORMAT, AvailabilityDay } from '.';
import Checkbox from './Checkbox';
import TimeInput from './TimeInput';

const INPUT_DATE_FORMAT = 'HH:mm';
const DEFAULT_START_TIME = '07:00:00';
const DEFAULT_END_TIME = '22:00:00';

export default function AvailabilityRow({
  availability,
  disabled,
  register,
}: {
  availability: AvailabilityDay;
  disabled: boolean;
  register: UseFormRegister<FieldValues>;
}) {
  const [visible, setVisible] = useState(!availability.notAvailable);
  const [startTime, setStartTime] = useState(
    formatTimeforInput(availability.startTime ?? DEFAULT_START_TIME)
  );
  const [endTime, setEndTime] = useState(
    formatTimeforInput(availability.endTime ?? DEFAULT_END_TIME)
  );

  const date = parse(
    availability.date,
    AVAILABILITY_API_DATE_FORMAT,
    new Date()
  );

  return (
    <tr
      key={availability.date}
      className={clsx(
        'whitespace-nowrap text-sm text-secondaryBlack',
        disabled && 'opacity-50'
      )}
    >
      <td className="px-1 sm:px-2 py-2 pl-0">
        <input
          type="hidden"
          value={availability.date}
          {...register(`[${availability.date}][date]`)}
        />
        <Switch.Group as="div" className="flex items-center">
          <Switch
            name={`[${availability.date}][available]`}
            checked={visible}
            disabled={disabled}
            onChange={setVisible}
            className={clsx(
              'relative inline-flex h-8 w-15 flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
              visible
                ? 'bg-primaryGreen border-primaryGreen'
                : 'bg-grey5 border-grey5'
            )}
          >
            <span
              aria-hidden="true"
              className={clsx(
                'pointer-events-none inline-block h-6 w-6 transform rounded-full bg-secondaryWhite shadow ring-0 transition duration-200 ease-in-out',
                visible ? 'translate-x-7' : 'translate-x-0  '
              )}
            />
          </Switch>
          <Switch.Label as="span" className="pl-2 sm:pl-4 text-sm">
            <span className="font-bold">{format(date, 'E')}</span>
            <br />
            <span>{format(date, 'd MMM')}</span>
          </Switch.Label>
        </Switch.Group>
      </td>
      {visible && (
        <>
          <td className="px-1 sm:px-2 py-2">
            <TimeInput
              id={`[${availability.date}][startTime]`}
              value={startTime}
              min="0:00"
              max={format(
                sub(parse(endTime, INPUT_DATE_FORMAT, new Date()), {
                  minutes: 1,
                }),
                INPUT_DATE_FORMAT
              )}
              disabled={disabled}
              onChange={(event) => setStartTime(event.target.value)}
              register={register}
            />
          </td>
          <td className="px-1 sm:px-2 py-2">
            <TimeInput
              id={`[${availability.date}][endTime]`}
              value={endTime}
              min={format(
                add(parse(startTime, INPUT_DATE_FORMAT, new Date()), {
                  minutes: 1,
                }),
                INPUT_DATE_FORMAT
              )}
              max="23:59"
              disabled={disabled}
              onChange={(event) => setEndTime(event.target.value)}
              register={register}
            />
          </td>
          <td className="px-1 sm:px-2 py-2">
            <Checkbox
              id={`[${availability.date}][sleepOver]`}
              defaultChecked={availability.sleepOver}
              disabled={disabled}
              register={register}
            />
          </td>
          <td className="px-1 sm:px-2 py-2 pr-0">
            <Checkbox
              id={`[${availability.date}][activeNight]`}
              defaultChecked={availability.activeNight}
              disabled={disabled}
              register={register}
            />
          </td>
        </>
      )}
    </tr>
  );
}

const formatTimeforInput = (time: string) =>
  format(parse(time, 'HH:mm:ss', new Date()), INPUT_DATE_FORMAT);
