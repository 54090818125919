enum NavLinks {
  Home = '/',
  Profile = '/profile',
  MyShifts = '/my-shifts',
  ShiftOffers = '/shift-offers',
  Timesheets = '/timesheets',
  Notifications = '/notifications',
  Availability = '/availability',
  Help = 'https://oncall.atlassian.net/servicedesk/customer/portal/17',
}

export default NavLinks;
