import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';

export const SpinnerIcon = ({
  isLoading,
  staticIcon,
  className,
}: {
  isLoading: boolean;
  staticIcon?: IconName;
  className?: string;
}) => {
  return (
    <>
      {isLoading ? (
        <CircularProgress
          size={16}
          sx={{ color: 'black' }}
          className={className}
        />
      ) : (
        <>
          {staticIcon ? (
            <FontAwesomeIcon icon={['fas', staticIcon]} className={className} />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
