import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { iconForFacility } from '../constants';
import { Shift } from '../models';

const getFacilityBadges = (shift: Shift) => {
  return (
    <div className="icon-text-group">
      {(shift.sleepOver || !!shift.sleepoverStartAndEndTime) && (
        <div key="sleepover">
          <FontAwesomeIcon
            icon={['fas', iconForFacility['Sleepover']]}
            size="sm"
          />
          &ensp;
          <span>
            {'Sleepover ' +
              (shift.sleepoverStartAndEndTime
                ? `(${shift.sleepoverStartAndEndTime})`
                : '')}
          </span>
        </div>
      )}

      {shift.activeNight && (
        <div key="activenight">
          <FontAwesomeIcon
            icon={['fas', iconForFacility['Active night']]}
            size="sm"
          />
          &ensp;
          <span>Active night</span>
        </div>
      )}

      {shift.rosterLine && (
        <div key="rosterline">
          <FontAwesomeIcon
            icon={['fas', iconForFacility['Roster line']]}
            size="sm"
          />
          &ensp;
          <span>Roster line</span>
        </div>
      )}
    </div>
  );
};

export default getFacilityBadges;
