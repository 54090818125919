import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonBase } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { redirect, useParams } from 'react-router';

import { getTimesheet, submitTimesheet } from '../services/TimesheetApi';
import { TimesheetSubmitRequest } from '../services/models/TImesheetSubmitRequest';
import { EntityType } from '../shared/EntityType';
import NavLinks from '../shared/NavLinks';
import ShiftStatus from '../shared/ShiftStatus';
import TestId from '../shared/TestId';
import AlertMessage from '../shared/components/AlertMessage';
import LoadingSpinner from '../shared/components/LoadingSpinner';
import ShiftDetail from '../shared/components/ShiftDetail';
import { SpinnerIcon } from '../shared/components/SpinnerIcon';
import Title from '../shared/components/Title';
import { timeFormat } from '../shared/constants';
import { Shift } from '../shared/models';
import { Timesheet } from '../shared/models/Timesheet';
import TimesheetEdit from './TimesheetEdit';

const getQuery = (id: number) => ({
  queryKey: ['timesheet', id],
  queryFn: async () => getTimesheet(id),
});

export default function TimesheetDetails() {
  const { id } = useParams();
  if (!id) {
    throw redirect(NavLinks.Timesheets);
  }

  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery(getQuery(parseInt(id as string)));

  const [isEditing, setEditing] = useState<boolean>(false);
  const [timesheet, setTimesheet] = useState<Timesheet>({} as Timesheet);

  const {
    isError: isSubmitError,
    isSuccess: isSubmitSuccess,
    mutate: submit,
    isLoading: isSubmitting,
  } = useMutation({
    mutationFn: () => {
      const params: TimesheetSubmitRequest = {
        actualStartTime: moment(timesheet.actualStartDate).format(timeFormat),
        actualEndTime: moment(timesheet.actualEndDate).format(timeFormat),
        participantTransportInKms:
          timesheet.actualParticipantTransportDistanceInKm,
        reasonsInB64: btoa(timesheet.reason || ''),
        mealBreakInMins: timesheet.actualBreakTimeInMinutes,
        sleepoverActiveHours: timesheet.actualSleepoverActiveHours,
      };

      return submitTimesheet(parseInt(id), params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['timesheet', id] });
      setTimesheet({
        ...timesheet,
        timeSheetStatus: ShiftStatus.SUBMITTED,
      });
    },
  });

  const showSubmit =
    timesheet?.timeSheetStatus &&
    [ShiftStatus.DUENOW, ShiftStatus.OVERDUE].includes(
      timesheet.timeSheetStatus.toUpperCase() as ShiftStatus
    );

  useEffect(() => {
    if (data) {
      setTimesheet({
        ...data,
        actualStartDate: data.startDate,
        actualEndDate: data.endDate,
        actualParticipantTransportDistanceInKm:
          data.participantTransportDistanceInKm,
        actualBreakTimeInMinutes: data.breakTimeInMinutes,
      });
    }
  }, [data]);

  const onSave = (values: Shift) => {
    setTimesheet({ ...timesheet, ...values });
    setEditing(false);
  };

  const message: JSX.Element = isSubmitSuccess ? (
    <AlertMessage className="font-bold">Timesheet submitted!</AlertMessage>
  ) : (
    <></>
  );

  return (
    <>
      <Title title="Timesheet details" />

      {!isLoading && timesheet ? (
        <ShiftDetail
          entityType={EntityType.Timesheet}
          shift={timesheet}
          message={message}
        >
          <>
            {showSubmit && (
              <div className="mt-6 text-sm">
                <div>
                  If you are happy with your timesheet details, click the
                  ‘Submit’ button below to submit your timesheet; otherwise
                  click the ‘Changes’ button first to make any corrections.
                </div>

                <div
                  className={
                    'mt-6 flex space-x-2 sm:space-x-6' +
                    (isSubmitting ? ' opacity-60' : '')
                  }
                >
                  <ButtonBase
                    className="border border-solid border-primaryOrange text-md bg-secondaryWhite h-11 px-2 sm:px-8 py-2.5 grow"
                    disabled={isSubmitting}
                    data-testid={TestId.ChangeTimesheetButton}
                    onClick={() => setEditing(true)}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'pencil']}
                      className="mr-3"
                    />
                    <span>Changes</span>
                  </ButtonBase>

                  <ButtonBase
                    className="border border-solid border-primaryGreen text-md bg-secondaryWhite h-11 px-2 sm:px-8 py-2.5 grow"
                    onClick={() => submit()}
                    disabled={isSubmitting}
                    data-testid={TestId.SubmitTimesheetButton}
                  >
                    <SpinnerIcon
                      isLoading={isSubmitting}
                      staticIcon="check"
                      className="mr-3"
                    />
                    <span>Submit</span>
                  </ButtonBase>
                </div>

                {isSubmitError && (
                  <div className="mt-5 text-sm text-red">
                    Sorry, an error has occurred submitting this timesheet.
                    Please try again later.
                  </div>
                )}
              </div>
            )}
            <TimesheetEdit
              timesheet={timesheet}
              open={isEditing}
              onSave={onSave}
              onClose={() => setEditing(false)}
            />
          </>
        </ShiftDetail>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
}
