import { useInfiniteQuery } from '@tanstack/react-query';

import { getMyShifts } from '../services/ShiftApi';
import { EntityType } from '../shared/EntityType';
import ShiftList from '../shared/components/ShiftList';
import Title from '../shared/components/Title';
import { useHandleScroll } from '../shared/hooks/useHandleScroll';

export default function MyShiftsList() {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['shifts'],
    queryFn: getMyShifts,
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  useHandleScroll(hasNextPage, fetchNextPage, isFetching);

  return (
    <>
      <Title title="My Shifts" />

      <ShiftList
        entityType={EntityType.MyShift}
        data={data}
        error={error}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        status={status}
      />
    </>
  );
}
