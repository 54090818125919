import { useEffect } from 'react';

export function useHandleScroll(
  hasNextPage: boolean | undefined,
  fetchNextPage: () => void,
  isFetching: boolean
) {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleScroll = (e: any) => {
      const { scrollHeight, scrollTop, clientHeight } =
        e.target.scrollingElement;
      if (
        !isFetching &&
        hasNextPage &&
        scrollHeight - scrollTop <= clientHeight * 1.2
      ) {
        fetchNextPage();
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [hasNextPage, fetchNextPage, isFetching]);
}
