import { useMsal } from '@azure/msal-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { b2cPolicies, b2cTenant, msalConfig } from '../../authConfig';
import NavLinks from '../NavLinks';
import { getInitials } from '../util/getInitials';
import { profileDetailQuery } from '../util/profileDetailQuery';

export default function SignInSignOutButton() {
  const { instance } = useMsal();
  const queryClient = useQueryClient();

  const { data: profile } = useQuery(profileDetailQuery());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    queryClient.clear();
    instance.logoutRedirect();
  };

  return (
    <div>
      <button
        className="h-11 w-11 mr-2 bg-grey m-1 border-grey6 bg-grey6 rounded-full flex items-center justify-center text-secondaryWhite text-sm font-bold"
        onClick={handleClick}
        aria-label="Your profile and settings"
      >
        {getInitials(profile?.displayName ?? ' ')}
      </button>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={isOpen}
        className="top-2"
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Link onClick={handleClose} to={NavLinks.Profile}>
          <MenuItem key="profile">Profile</MenuItem>
        </Link>
        <Link onClick={handleClose} to={NavLinks.Notifications}>
          <MenuItem key="notifications">Notifications</MenuItem>
        </Link>
        <Link
          onClick={handleClose}
          to={`https://${
            b2cPolicies.authorityDomain
          }/${b2cTenant}.onmicrosoft.com/oauth2/v2.0/authorize?p=${
            b2cPolicies.names.changePassword
          }&client_id=${
            msalConfig.auth.clientId
          }&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
            window.location.origin
          )}&scope=openid&response_type=id_token`}
        >
          <MenuItem key="change-password">Change Password</MenuItem>
        </Link>
        <MenuItem onClick={handleLogout} key="logout">
          Logout
        </MenuItem>
        {/* : (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) ?
                     inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
                     */}
      </Menu>
    </div>
  );
}
